<template>
  <path id="Tracé_580" data-name="Tracé 580" d="M6.778,53.452l-.4,4.4-2.917-.264.886-9.858,9.743,1.005-.3,2.914-5.112-.529a26.333,26.333,0,0,0,40.229,1.184l2.179,1.957a29.289,29.289,0,0,1-44.313-.814ZM10.25,34.716A19.036,19.036,0,1,1,29.286,53.752,19.057,19.057,0,0,1,10.25,34.716ZM21.964,44.964v4.08a15.957,15.957,0,0,0,14.645,0v-4.08c0-3-4.369-4.393-7.322-4.393S21.964,41.967,21.964,44.964ZM36.608,31.576v1.883A6.971,6.971,0,0,1,34.433,38.6c2.9,1.143,5.1,3.293,5.1,6.364v2.165a16.106,16.106,0,1,0-20.5,0V44.964c0-3.071,2.206-5.221,5.1-6.364a6.971,6.971,0,0,1-2.176-5.142V31.576a7.062,7.062,0,0,1,7.322-7.112A7.062,7.062,0,0,1,36.608,31.576Zm-11.715,0v1.883a4.107,4.107,0,0,0,4.393,4.184,4.107,4.107,0,0,0,4.394-4.184V31.576a4.107,4.107,0,0,0-4.394-4.183A4.107,4.107,0,0,0,24.893,31.576ZM49.278,41.914l2.828-.759,1.19,4.44A26.371,26.371,0,0,0,36.222,9.278l.771-2.826A29.273,29.273,0,0,1,55.749,47.238l4.708-1.261.757,2.83L51.8,51.33ZM0,34.716A29.307,29.307,0,0,1,28.71,5.458L25.322,2.071,27.393,0l6.893,6.893-6.893,6.891-2.071-2.068L28.649,8.39A26.355,26.355,0,0,0,4.85,44.614l-2.712,1.1A29.1,29.1,0,0,1,0,34.716Z" transform="translate(0.5 0.707)" stroke="rgba(0,0,0,0)" stroke-width="1"/>
</template>

<script>
export default {
name: "IconInterimaire"
}
</script>

<style scoped>

</style>